import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

import axios from "axios";
window.axios = axios.create({
	headers: { Authorization: store.getters.getToken },
});

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import VueViewer from 'v-viewer'
Vue.use(VueViewer);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
	methods: {
		acceso : (value) => {
			// console.log({value, permiso:  store.getters.getPermisos[value], resultado : !!(store.getters.getPermisos[value] & Number(store.getters.getTokenPayload.permisos)) })
			return !!(store.getters.getPermisos[value] & Number(store.getters.getTokenPayload.permisos))}
	}
}).$mount("#app");
