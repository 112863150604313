<template>
  <v-app>
    <v-app-bar app clipped-right v-if="$store.getters.isLoggedIn">
      <v-btn @click="menuShow = !menuShow" icon>
        <v-icon v-text="'mdi-menu'" />
      </v-btn>
      <v-toolbar-title>
        {{ portal }}
        {{ $store.getters.getNombreEmpresa.toUpperCase() }}
      </v-toolbar-title>
      <v-spacer />

      <v-btn icon @click="changeTheme">
        <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
        <v-icon v-else>mdi-weather-night</v-icon>
      </v-btn>

      <MensajesSinLeer :key="$route.fullPath + reloadIdAux" />

      <alertas-estudios v-if="$root.acceso('EDITAR_ESTUDIOS')" />

      <v-btn icon @click.stop="showFavorites = !showFavorites">
        <v-icon>mdi-star</v-icon>
      </v-btn>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-on="on"
            v-bind="attrs"
            small
            text
            @click.stop="logout"
            :icon="$vuetify.breakpoint.mobile"
            color="error"
          >
            <span v-show="!$vuetify.breakpoint.mobile">Cerrar sesion</span>
            <v-icon :right="!$vuetify.breakpoint.mobile">mdi-logout</v-icon>
          </v-btn>
        </template>
        {{ $store.getters.getTokenPayload.nombre }}
      </v-tooltip>
    </v-app-bar>

    <v-navigation-drawer
      :mini-variant="menuShow"
      app
      :color="$vuetify.theme.dark ? '#1E1E1E' : 'primary'"
      inset
      v-if="$store.getters.isLoggedIn && themeLoaded"
      :disable-route-watcher="true"
      touchless
      permanent
    >
      <div class="d-flex">
        <v-avatar
          tile
          :size="!menuShow ? 200 : 60"
          class="pa-4 mx-auto"
          :key="theme"
        >
          <v-img
            v-if="!menuShow"
            contain
            :src="`./img/logo_blanco_${$store.getters.getNombreEmpresa}.png`"
          ></v-img>
          <v-img
            v-else
            contain
            :src="`./img/fav_${$store.getters.getNombreEmpresa}.png`"
          ></v-img>
        </v-avatar>
      </div>

      <v-tabs
        :background-color="$vuetify.theme.dark ? '' : 'primary'"
        vertical
        slider-size="5"
      >
        <v-tab
          active-class="active-tab"
          style="height: 40px"
          class="rounded-r-pill mr-1"
          v-for="link in menu.filter(
            (m) => !m.acceso || $root.acceso(m.acceso)
          )"
          :key="link.url"
          :to="link.url"
        >
          <v-list-item-icon class="my-auto">
            <v-icon color="white" v-text="link.icon" />
          </v-list-item-icon>
          <v-list-item-content
            class="text-start white--text"
            style="font-size: 0.7rem"
          >
            <div class="d-flex">
              {{ link.texto }}
              <small v-if="link.pruebas" class="align-start ml-2"
                >PRUEBAS</small
              >
            </div>
          </v-list-item-content>
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>

    <v-navigation-drawer style="z-index: 1000; width: unset" v-model="showFavorites" clipped absolute right temporary>
      <Favorites :key="keyFav" />
    </v-navigation-drawer>

    <v-main :style="`background: ${$vuetify.theme.dark ? '' : '#e4e4e4'};`">
      <v-container fluid>
        <router-view :key="$route.fullPath" />
      </v-container>

      <v-snackbar v-model="snack" timeout="4000" color="primary" type="error">
				{{ msg }}
			</v-snackbar>

      <v-speed-dial
        bottom
        right
        :style="`${!$vuetify.breakpoint.smAndUp ? 'right: 56px' : ''}`"
        fixed
        v-if="development && themeLoaded"
      >
        <template v-slot:activator>
          <v-btn small color="primary" dark fab>
            <v-icon v-text="'mdi-palette'" />
          </v-btn>
        </template>
        <v-tooltip
          left
          :key="theme.name"
          :color="theme.light.secondary"
          v-for="theme in themes.filter(({ name }) => name != theme)"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :key="theme.name"
              :color="theme.light.primary"
              @click="setTheme(theme.name)"
              dark
              fab
              small
              v-text="theme.name[0]"
            />
          </template>
          {{ theme.name.toUpperCase() }}
        </v-tooltip>
      </v-speed-dial>
    </v-main>
    <ConfirmDialog ref="confirm" />
    <Snack ref="snack" />
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    AlertasEstudios: () => import("@/components/AlertasEstudios.vue"),
    MensajesSinLeer: () => import("@/components/MensajesSinLeer.vue"),

    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    Snack: () => import("@/components/Snack.vue"),
    Favorites: () => import("@/components/Favorites.vue"),
  },
  metaInfo() {
    return {
      title: this.portal + " | " + this.$store.getters.getNombreEmpresa,
      link: [
        {
          rel: "icon",
          href: `./img/fav_${this.$store.getters.getNombreEmpresa}.png`,
        },
      ],
    };
  },

  data: () => {
    return {
      msg: "",
      snack: false,
      reloadIdAux: 0,
      themeLoaded: false,
      development: process.env.NODE_ENV === "development",
      menuShow: true,
      showFavorites: true,
      keyFav: 0,
      portal: ((
        s = window.location.host.replaceAll(/www./g, "").split(".")[0]
      ) => s && s[0] && s[0].toUpperCase() + s.slice(1))(),
      menu: [
        { url: "/", texto: "Resumen", icon: "mdi-home" },
        {
          url: "/estudios",
          texto: "Estudios",
          icon: "mdi-table",
          acceso: "VER_ESTUDIOS",
        },
        {
          url: "/borradores",
          texto: "Borradores",
          icon: "mdi-eraser",
          acceso: "ENVIAR_ESTUDIOS",
        },
        // { url: "/incidencias", texto: "Incidencias", icon: "mdi-alert-circle", acceso: 'VER_INCIDENCIAS' },
        {
          url: "/cuentas",
          texto: "Cuentas de usuario",
          icon: "mdi-account-group",
          acceso: "CONTROL_USUARIOS",
        },
        {
          url: "/papelera",
          texto: "Papelera",
          icon: "mdi-delete-variant",
          acceso: "EDITAR_ESTUDIOS",
        },
        {
          url: "/multicups",
          texto: "MultiCups",
          icon: "mdi-view-list",
          acceso: "DEV",
        },
        {
          url: "/controles",
          texto: "Controles",
          icon: "mdi-cog",
          acceso: "EDITAR_ESTUDIOS",
        },
        {
          url: "/pruebas",
          texto: "Pruebas",
          icon: "mdi-test-tube",
          acceso: "DEV",
        },
      ],
    };
  },

  methods: {
    async logout() {
      (await this.$refs.confirm.open(
        "Cerrar sesión",
        "¿Estás seguro de que quieres cerrar sesión?"
      ))
        ? this.$store
            .dispatch("logout")
            .then(() => this.$router.push({ name: "Login" }))
        : null;
    },
    setTheme(theme) {
      return new Promise(async (resolve) => {
        this.themeLoaded = false;
        if (typeof theme != "object")
          theme = this.themes.find(({ name }) => name == theme);
        this.theme = theme.name;
        Object.keys(theme.light).forEach((key) => {
          this.$vuetify.theme.themes.light[key] = theme.light[key];
          this.$vuetify.theme.themes.dark[key] = theme.light[key];
        });
        this.$vuetify.theme.themes.name = theme.name;
        this.themeLoaded = true;
        await this.$store.dispatch("setNombreEmpresa", theme.name);
        resolve();
      });
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("theme", this.$vuetify.theme.dark);
    },
  },
  async beforeCreate() {
    let dominio = !/(\d{1,3}\.?){4}/.test(window.location.host)
      ? window.location.host.replaceAll(/www./g, "").split(".")[1] ||
        "alumbraenergia"
      : "alumbraenergia";

    await this.$store.dispatch("setNombreEmpresa", dominio);
    let { data: themes } = await axios({ url: "/themes.json" });
    let aux = {};
    Object.values(themes.themes).forEach(
      (t) => (aux[t.name] = t.light.primary)
    );
    await this.$store.dispatch("setColoresEmpresas", aux);
    this.themes = Object.values(themes.themes);
    let theme = themes.themes[dominio];
    this.theme = theme.name;
    Object.keys(theme.light).forEach((key) => {
      this.$vuetify.theme.themes.light[key] = theme.light[key];
      this.$vuetify.theme.themes.dark[key] = theme.light[key];
    });
    this.$vuetify.theme.themes.name = theme.name;
    this.themeLoaded = true;
  },
  mounted() {
    const vm = this;
    this.$root.$on("snack", (msg, type='info') => {
      // this.snack = false;
      // this.snack = true;
      // this.msg = msg;
      vm.$refs.snack.open( msg, type )
    });

    this.$root.$on("reloadMensajesSinLeer", () => {
      this.reloadIdAux++;
    });

    this.$root.$on("getFav", () => {
      this.keyFav++;
    });

    setInterval(() => {
      this.reloadIdAux++;
    }, 1000 * 60 * 2)

  },
};
</script>

<style lang="scss">
.border-l {
  border-left: 5px solid transparent;
}
.border-l-white {
  border-left: 5px solid white;
}
.active-tab {
  background-color: #00000036;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--v-primary-base);
}
*.theme--dark ::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #121212;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #bebebe;
}

.v-chip--active.theme--dark {
  border-style: unset !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: #f1f1f1 !important;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: #272727 !important;
}

.v-data-table.fixed-checkbox
  table
  > tbody
  > tr
  > td:nth-child(1):not(.remove-filter),
.v-data-table.fixed-checkbox
  table
  > thead
  > tr
  > th:nth-child(1):not(.remove-filter) {
  // position: sticky !important;
  // position: -webkit-sticky !important;
  // left: 0;
  position: sticky;
  left: 0;
  z-index: 4;
  background: inherit;
}

.v-data-table.fixed-checkbox
  table
  > tbody
  > tr
  > td:nth-child(1):not(.remove-filter),
.v-data-table.fixed-checkbox
  table
  > thead
  > tr
  > th:nth-child(1):not(.remove-filter) {
  // position: sticky !important;
  // position: -webkit-sticky !important;
  // left: 0;
  position: sticky;
  left: 0;
  z-index: 4;
  background: inherit;
}

.v-data-table.theme--light
  table
  > tbody
  > tr:not(.v-data-table__selected):not(:hover)
  > td:nth-child(1):not(.remove-filter),
.v-data-table.theme--light
  table
  > thead
  > tr:not(.v-data-table__selected):not(:hover)
  > th:nth-child(1):not(.remove-filter) {
  background: #ffffff;
}

.v-data-table.theme--dark
  table
  > tbody
  > tr:not(.v-data-table__selected):not(:hover)
  > td:nth-child(1):not(.remove-filter),
.v-data-table.theme--dark
  table
  > thead
  > tr:not(.v-data-table__selected):not(:hover)
  > th:nth-child(1):not(.remove-filter) {
  background: #1e1e1e;
}
</style>
