import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/borradores",
    name: "Borradores",
    component: () => import("@/views/Borradores.vue"),
    meta: { requiresAuth: true, acceso: "ENVIAR_ESTUDIOS" },
  },
  {
    path: "/enviar",
    name: "EnviarEstudio",
    component: () => import("@/views/EnviarEstudio.vue"),
    meta: { requiresAuth: true, acceso: "ENVIAR_ESTUDIOS" },
  },
  {
    path: "/estudios",
    name: "Estudios",
    component: () => import("@/views/ListadoEstudios.vue"),
    meta: { requiresAuth: true, acceso: "VER_ESTUDIOS" },
  },
  {
    path: "/papelera",
    name: "Papelera",
    component: () => import("@/views/ListadoEstudios.vue"),
    meta: { requiresAuth: true, acceso: "EDITAR_ESTUDIOS" },
    props: {
      eliminados: true,
    },
  },
  {
    path: "/detalles_estudio",
    name: "DetallesEstudio",
    component: () => import("@/views/DetallesEstudio.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cuentas",
    name: "Cuentas",
    component: () => import("@/views/Cuentas.vue"),
    meta: { requiresAuth: true, requiresAdmin: true, acceso: "VER_USUARIOS" },
  },
  {
    path: "/crear_cuenta",
    name: "CrearCuenta",
    component: () => import("@/views/CrearCuenta.vue"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      acceso: "CONTROL_USUARIOS",
    },
  },
  {
    path: "/multicups",
    name: "MultiCUPS",
    component: () => import("@/views/MultiCUPS.vue"),
    meta: { requiresAuth: true, acceso: "DEV" },
  },
  {
    path: "/controles",
    name: "Controles",
    component: () => import("@/views/Controles.vue"),
    meta: { requiresAuth: true, acceso: "EDITAR_ESTUDIOS" },
  },
  {
    path: "/pruebas",
    name: "Pruebas",
    component: () => import("@/views/Pruebas.vue"),
    meta: { requiresAuth: true, acceso: "DEV" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.soloLocal)) {
    if (process.env.NODE_ENV == "development") next();
    else next({ name: "Home" });
  }

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    store.getters.getToken === null
  ) {
    let msg = store.getters.isLoggedIn != null ? "La sesión ha expirado" : "";
    await store.dispatch("logout");
    next({ name: "Login", query: { redirect: to.path, msg } });
  } else {
    store.dispatch('refreshToken');
  }

  if (to.matched.some((record) => record.meta.acceso)) {
    const acceso = to.matched.find((record) => record.meta.acceso).meta.acceso;
    if (
      !!(
        store.getters.getTokenPayload.permisos &
        store.getters.getPermisos[acceso]
      )
    )
      next();
    else next({ name: "Home" });
  }

  next();
});

export default router;
