import Vue from "vue";
import Vuex from "vuex";
// import { decode64 } from "@/utils/index.js";
import axios from "axios";

function decode64(data) {
  return new TextDecoder().decode(urlB64ToUint8Array(data));
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i);

  return outputArray;
}

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: localStorage.getItem("token") || null,
		nombreEmpresa: "",
		coloresEmpresas: null,
		permisos: JSON.parse(localStorage.getItem("permisos")) || null,
		estados: JSON.parse(localStorage.getItem("estados")) || null,

    refreshLock: Date.now(),
	},
	getters: {
		getToken: (state) => state.token || null,
		getTokenPayload: (state) =>
			state.token ? JSON.parse(decode64(state.token.split(".")[1])) : null,
		isLoggedIn: (state) => !!state.token || null,
		isAdmin: (state, getters) =>
			getters.getTokenPayload
				? Boolean(Number(getters.getTokenPayload.admin))
				: false,
		getNombreEmpresa: (state) => state.nombreEmpresa || "",
		getColoresEmpresas: (state) => state.coloresEmpresas || "",
		getPermisos: (state) => state.permisos,
		getEstados: (state) => { 
			console.log(state.estados);
			return state.estados.reduce( ( r, {estado, color, icon} ) => {
				r[estado] = { color, icon }
				return r
			} , Object.create(null))
		 }
	},
	mutations: {
		logout(state) {
			return new Promise((resolve) => {
				localStorage.removeItem("token");
				localStorage.removeItem("permisos");
				state.token = null;
				resolve();
			});
		},
		login(state, token) {
			localStorage.setItem("token", token);
			state.token = token;
		},
		estados(state, estados) {
			localStorage.setItem("estados", JSON.stringify(estados));
			state.estados = estados;
		},
		setNombreEmpresa(state, domain) {
			return new Promise((resolve) => {
				state.nombreEmpresa = domain;
				resolve();
			});
		},
		setColoresEmpresas(state, obj) {
			return new Promise(async (resolve) => {
				state.coloresEmpresas = obj;
				resolve();
			});
		},
		setPermisos(state, per) {
			return new Promise(async (resolve) => {
				localStorage.setItem("permisos", JSON.stringify(per));
				state.permisos = per;
				resolve();
			});
		},
	},
	actions: {
		login({ commit }, { user, password }) {
			return new Promise(async (resolve, reject) => {
				try {
					const { data: token } = await axios({
						method: "POST",
						url: `${process.env.VUE_APP_API_URL}/login.php`,
						data: {
							user,
							password,
						},
					});

					const { data: permisos } = await axios({
						method: "GET",
						url: `${process.env.VUE_APP_API_URL}/opciones/permisos.php`,
					});

					let per = {};
					permisos.forEach(
						({ permiso, valor }) => (per[permiso] = Number(valor))
					);

					axios = axios.create({
						headers: { Authorization: token },
					});

					await commit("login", token);
					await commit("setPermisos", per);
					resolve();
				} catch (e) {
					console.error(e);
					reject();
				}
			});
		},
		refreshToken({ commit, getters, state }) {
      return new Promise(async (resolve) => {
        if (Date.now() < state.refreshLock || !getters.getToken) {
          resolve();
          return;
        }

        const { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/refresh_token.php`,
					headers: { Authorization: getters.getToken },
        });
        let per = {};
        data.permisos.forEach(
          ({ permiso, valor }) => (per[permiso] = Number(valor))
        );

        state.refreshLock = Date.now() + 1000 * 60 * 10;

        resolve(
          await Promise.all([
            commit("login", data.token),
            commit("estados", data.estados),
            commit("setPermisos", per),
          ])
        );
      });
    },
		logout({ commit }) {
			return new Promise(async (resolve) => {
				commit("logout");
				resolve();
			});
		},
		setNombreEmpresa({ commit }, domain) {
			return new Promise(async (resolve) => {
				await commit("setNombreEmpresa", domain);
				resolve();
			});
		},
		setColoresEmpresas({ commit }, obj) {
			return new Promise(async (resolve) => {
				await commit("setColoresEmpresas", obj);
				resolve();
			});
		},
		setPermisos({ commit }, permisos) {
			return new Promise(async (resolve) => {
				await commit("setPermisos", permisos);
				resolve();
			});
		},
	},
	modules: {},
});
