var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.$store.getters.isLoggedIn)?_c('v-app-bar',{attrs:{"app":"","clipped-right":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menuShow = !_vm.menuShow}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-menu')}})],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.portal)+" "+_vm._s(_vm.$store.getters.getNombreEmpresa.toUpperCase())+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.changeTheme}},[(_vm.$vuetify.theme.dark)?_c('v-icon',[_vm._v("mdi-white-balance-sunny")]):_c('v-icon',[_vm._v("mdi-weather-night")])],1),_c('MensajesSinLeer',{key:_vm.$route.fullPath + _vm.reloadIdAux}),(_vm.$root.acceso('EDITAR_ESTUDIOS'))?_c('alertas-estudios'):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.showFavorites = !_vm.showFavorites}}},[_c('v-icon',[_vm._v("mdi-star")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","icon":_vm.$vuetify.breakpoint.mobile,"color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}]},[_vm._v("Cerrar sesion")]),_c('v-icon',{attrs:{"right":!_vm.$vuetify.breakpoint.mobile}},[_vm._v("mdi-logout")])],1)]}}],null,false,1914536237)},[_vm._v(" "+_vm._s(_vm.$store.getters.getTokenPayload.nombre)+" ")])],1):_vm._e(),(_vm.$store.getters.isLoggedIn && _vm.themeLoaded)?_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.menuShow,"app":"","color":_vm.$vuetify.theme.dark ? '#1E1E1E' : 'primary',"inset":"","disable-route-watcher":true,"touchless":"","permanent":""}},[_c('div',{staticClass:"d-flex"},[_c('v-avatar',{key:_vm.theme,staticClass:"pa-4 mx-auto",attrs:{"tile":"","size":!_vm.menuShow ? 200 : 60}},[(!_vm.menuShow)?_c('v-img',{attrs:{"contain":"","src":("./img/logo_blanco_" + (_vm.$store.getters.getNombreEmpresa) + ".png")}}):_c('v-img',{attrs:{"contain":"","src":("./img/fav_" + (_vm.$store.getters.getNombreEmpresa) + ".png")}})],1)],1),_c('v-tabs',{attrs:{"background-color":_vm.$vuetify.theme.dark ? '' : 'primary',"vertical":"","slider-size":"5"}},_vm._l((_vm.menu.filter(
            function (m) { return !m.acceso || _vm.$root.acceso(m.acceso); }
          )),function(link){return _c('v-tab',{key:link.url,staticClass:"rounded-r-pill mr-1",staticStyle:{"height":"40px"},attrs:{"active-class":"active-tab","to":link.url}},[_c('v-list-item-icon',{staticClass:"my-auto"},[_c('v-icon',{attrs:{"color":"white"},domProps:{"textContent":_vm._s(link.icon)}})],1),_c('v-list-item-content',{staticClass:"text-start white--text",staticStyle:{"font-size":"0.7rem"}},[_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(link.texto)+" "),(link.pruebas)?_c('small',{staticClass:"align-start ml-2"},[_vm._v("PRUEBAS")]):_vm._e()])])],1)}),1)],1):_vm._e(),_c('v-navigation-drawer',{staticStyle:{"z-index":"1000","width":"unset"},attrs:{"clipped":"","absolute":"","right":"","temporary":""},model:{value:(_vm.showFavorites),callback:function ($$v) {_vm.showFavorites=$$v},expression:"showFavorites"}},[_c('Favorites',{key:_vm.keyFav})],1),_c('v-main',{style:(("background: " + (_vm.$vuetify.theme.dark ? '' : '#e4e4e4') + ";"))},[_c('v-container',{attrs:{"fluid":""}},[_c('router-view',{key:_vm.$route.fullPath})],1),_c('v-snackbar',{attrs:{"timeout":"4000","color":"primary","type":"error"},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")]),(_vm.development && _vm.themeLoaded)?_c('v-speed-dial',{style:(("" + (!_vm.$vuetify.breakpoint.smAndUp ? 'right: 56px' : ''))),attrs:{"bottom":"","right":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"small":"","color":"primary","dark":"","fab":""}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-palette')}})],1)]},proxy:true}],null,false,3929183472)},_vm._l((_vm.themes.filter(function (ref) {
                    var name = ref.name;

                    return name != _vm.theme;
})),function(theme){return _c('v-tooltip',{key:theme.name,attrs:{"left":"","color":theme.light.secondary},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:theme.name,attrs:{"color":theme.light.primary,"dark":"","fab":"","small":""},domProps:{"textContent":_vm._s(theme.name[0])},on:{"click":function($event){return _vm.setTheme(theme.name)}}},'v-btn',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(theme.name.toUpperCase())+" ")])}),1):_vm._e()],1),_c('ConfirmDialog',{ref:"confirm"}),_c('Snack',{ref:"snack"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }